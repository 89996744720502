import {
  CalendarPickerView,
  MobileDatePicker as MuiDatePicker,
  DateTimePicker,
} from "@mui/x-date-pickers";
import {
  DateAndTimePickerProps,
  DatePickerSeparateProps,
  DateRangePickerProps,
  StandardDatePickerProps,
} from "./AppPickers.props";
import React, { ForwardedRef, forwardRef, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { InputLabel } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import RangePicker from "react-datepicker";
import TextField from "@mui/material/TextField";
import { getDay } from "date-fns";
import { useDatePickerStyles } from "./AppPicker.styles";
import "./Calendar.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { enUS, it } from "date-fns/locale";
import i18n from "../../../config/i18n";
registerLocale("it", it);
registerLocale("enUS", enUS);
export { AppDatePicker as default };
export const AppDatePicker = forwardRef(function AppDatePicker(
  {
    value,
    label,
    className,
    onChange = () => {},
    minDate,
    maxDate,
    showTodayButton = true,
    readOnly = false,
    required = false,
    inputFormat = "dd/MM/yyyy",
  }: StandardDatePickerProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const [tempValue, setTempValue] = useState<string | null>(
    value?.toString() || null
  );
  const [language, setLanguage] = React.useState("it");
  React.useEffect(() => {
    const selectedLanguage = i18n.language;
    setLanguage(selectedLanguage);
  }, []);
  return (
    <>
      <div className={`w-full ${className}`}>
        {language == "it" ? (
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
            <MuiDatePicker
              inputRef={ref}
              label={label}
              inputFormat={inputFormat}
              value={(tempValue as string) || null}
              onChange={(e: Date | null, keyBoardValue: string | undefined) => {
                if (keyBoardValue) {
                  if (/\d+\d+\/\d+\d+\/\d+\d+\d+\d+/.test(keyBoardValue)) {
                    const [D, M, Y] = keyBoardValue.split("/");
                    setTempValue(M + "/" + D + "/" + Y);
                  }
                  return;
                }
                setTempValue(e?.toString() || null);
              }}
              onAccept={(e) => {
                onChange(e?.toString() || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  // sx={{ backgroundColor: "#17a2b8" }}
                  required={required}
                  helperText={inputFormat}
                />
              )}
              openTo={maxDate || minDate ? "year" : "day"}
              minDate={minDate}
              maxDate={maxDate}
              disableHighlightToday={!showTodayButton}
              disabled={readOnly}
            />
          </LocalizationProvider>
        ) : (
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={enUS}>
            <MuiDatePicker
              inputRef={ref}
              label={label}
              inputFormat={inputFormat}
              value={(tempValue as string) || null}
              onChange={(e: Date | null, keyBoardValue: string | undefined) => {
                if (keyBoardValue) {
                  if (/\d+\d+\/\d+\d+\/\d+\d+\d+\d+/.test(keyBoardValue)) {
                    const [D, M, Y] = keyBoardValue.split("/");
                    setTempValue(M + "/" + D + "/" + Y);
                  }
                  return;
                }
                setTempValue(e?.toString() || null);
              }}
              onAccept={(e) => {
                onChange(e?.toString() || null);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  required={required}
                  helperText={inputFormat}
                />
              )}
              openTo={maxDate || minDate ? "year" : "day"}
              minDate={minDate}
              maxDate={maxDate}
              disableHighlightToday={!showTodayButton}
              disabled={readOnly}
            />
          </LocalizationProvider>
        )}
      </div>
    </>
  );
});

export const DateRangePicker = forwardRef(DateRangePickerComponent);
export function DateRangePickerComponent(
  {
    startDate = null,
    endDate = null,
    required = false,
    className = "",
    label = "",
    disableOnDay = [],
    onChange = () => {},
    minDate = new Date(),
    ...props
  }: DateRangePickerProps,
  ref: ForwardedRef<HTMLInputElement | null>
) {
  const [start, setStart] = useState(startDate);
  const [end, setEnd] = useState(endDate);
  const isDesktopScreen = window.innerWidth >= 1310;

  // Passing color dynamically to the CSS file

  const handleDateChange = (dates) => {
    const [newStartDate, newEndDate] = dates;

    setStart(newStartDate);
    setEnd(newEndDate);

    onChange(dates);
  };

  return (
    <div
      className={`w-full ${className} `}
      style={{ zIndex: isDesktopScreen ? "10" : "inherit" }}
    >
      <RangePicker
        locale={i18n.language}
        className={"w-full h-full"}
        dateFormat="dd MMM,yyyy"
        isClearable
        required={required}
        monthsShown={2}
        minDate={minDate}
        sx={{
          svg: { color: "#17a2b8" },
          input: { color: "#17a2b8" },
          label: { color: "#17a2b8" },
          backgroundColor: "#17a2b8",
        }}
        maxDate={props.maxDate}
        selected={start}
        selectsRange
        startDate={start}
        endDate={end}
        withPortal //works for dekstop and mobile; it is done so that the calender does not come in modal
        autoComplete="off"
        customInput={
          <TextField
            autoComplete={Date.now().toString()}
            label={label}
            required={required}
            fullWidth
            inputRef={ref}
          />
        }
        filterDate={(e) => {
          const isEnabled = disableOnDay.indexOf(getDay(e)) > -1;
          return !isEnabled;
        }}
        onChange={handleDateChange}
      />
    </div>
  );
}

export function AppDateAndTimePicker({
  label,
  value,
  showTodayButton,
  minDateTime,
  onAccept,
  className = "",
  disableOnDay = [],
  required = false,
  readOnly = false,
}: DateAndTimePickerProps) {
  return (
    <div className={`w-full min-w-50 ${className}`}>
      <DateTimePicker
        disabled={readOnly}
        label={label}
        value={value}
        onChange={(e) => {
          console.log(e);
        }}
        renderInput={(params) => (
          <TextField {...params} fullWidth required={required} />
        )}
        disableHighlightToday={!showTodayButton}
        minDateTime={minDateTime}
        onAccept={onAccept}
        shouldDisableDate={(date: Date) => {
          return disableOnDay.includes(date.getDay());
        }}
        ampm={true}
      />
    </div>
  );
}

export const AppDatePickerSeparate = forwardRef(
  function AppDatePickerSeparateComponent(
    {
      onChange = () => {},
      label,
      disableOnDay = [],
      onAccept = () => {},
      onError = () => {},
      dayLabel = "Giorno",
      monthLabel = "Mese",
      yearLabel = "Anno",
      className = "",
      value,
      readOnly = false,
    }: DatePickerSeparateProps,
    ref: ForwardedRef<HTMLInputElement>
  ) {
    interface DatePickerFormat {
      label: string;
      views: CalendarPickerView[];
      inputFormat?: string;
      mask?: string;
      onChange?: () => void;
      disabled?: boolean;
    }

    const DatePickerStyle = useDatePickerStyles();
    const datePickers: Array<DatePickerFormat> = [];
    datePickers.push({
      label: dayLabel,
      views: ["day"],
      inputFormat: "dd",
      disabled: readOnly,
    });
    datePickers.push({
      label: monthLabel,
      views: ["month"],
      inputFormat: "MMM",
      disabled: readOnly,
    });
    datePickers.push({
      label: yearLabel,
      views: ["year"],
      inputFormat: "yyyy",
      disabled: readOnly,
    });
    return (
      <div className={`w-full h-full border-box flex flex-col  ${className}`}>
        <div className="h-8">
          <InputLabel>{label}</InputLabel>
        </div>

        <div
          className={`py-2 box-border items-center justify-center h-full flex flex-row gap-2 w-full ${DatePickerStyle.dateSeparated}`}
        >
          {datePickers.map((el: DatePickerFormat, index: number) => {
            return (
              <div className={"shrink h-full"} key={index}>
                <AppDatePicker
                  locale="it"
                  {...el}
                  value={value}
                  inputRef={ref}
                  components={{
                    OpenPickerIcon: KeyboardArrowDown,
                  }}
                  onChange={(date: Date | null) => {
                    // if (!date) {
                    //   return onChange(null);
                    // }
                    onChange(date?.toString() || null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      inputProps={{ readOnly: false }}
                      disabled={false}
                    />
                  )}
                  onAccept={(date: Date | null) => {
                    onChange(date?.toString() || null);
                  }}
                  onError={onError}
                  inputFormat="dd/MM/yyyy"
                  shouldDisableDate={(date: Date) => {
                    return disableOnDay.includes(date.getDay());
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);
