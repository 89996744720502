import InfiniteScroll from "react-infinite-scroll-component";
import { BookingVehicleInfoCard } from "../BookingVehicleInfoCard/BookingVehicleInfoCard";
import React, { useEffect, useRef, useState } from "react";
import { Vehicle } from "../../../@types/DTO/Vehicles";
import { ShowVehiclesProps } from "./ShowVehiclesProps";
import { Box, CircularProgress } from "@mui/material";
import { start } from "repl";
import "./Scrollbar.css";
import BookingGetters from "../../../store/onlineBooking/getters";
import { useTranslation } from "react-i18next";

export function ShowVehicles({
  vehiclesFilter = {
    status: {},
    category: {},
    type: {},
    TotalSeats: [],
    MacroGroup: {},
    Transmission: {},
  },
  elementsToShowInOnePage = 3,
  allVehicles = [],
  onVehicleSelect = () => {},
}: ShowVehiclesProps): JSX.Element {
  const [availableVehicles, setAvailableVehicles] = useState<Vehicle[]>([]);
  const [showingVehicles, setShowingVehicles] = useState<Vehicle[]>([]);
  const [isLoadingMoreVehicles, setIsLoadingMoreVehicles] =
    useState<boolean>(false);
  const { t: translate } = useTranslation();
  function loadMoreVehicles() {
    const result: Vehicle[] = availableVehicles;
    setIsLoadingMoreVehicles(true);
    if (result.length <= availableVehicles.length) {
      setShowingVehicles([...result]);
    }
    setIsLoadingMoreVehicles(false);
  }
  function segregateData() {
    setAvailableVehicles(
      (
        allVehicles.filter(
          (e) =>
            e.Status.toLowerCase() === "available" ||
            ((e.Status.toLowerCase() === "onrequest" ||
              e.Status.toLowerCase() === "Unavailable") &&
              e?.TotalCharge?.RateTotalAmount != 0)
        ) || []
      )
        .filter((vehicle) => {
          const length = Object.keys(vehiclesFilter.type).length;
          if (length < 1) {
            return true;
          }
          const fuelType =
            vehicle.Vehicle?.fuelType?.description?.toLowerCase() || "";
          return fuelType in vehiclesFilter.type;
        })
        .filter((e) => {
          if (!vehiclesFilter.TotalSeats.length) {
            return true;
          }
          return vehiclesFilter.TotalSeats.filter(
            (v) => v == (e.Vehicle?.seats.toString() || "0")
          ).length;
        })
        .filter((e) => {
          if (!Object.keys(vehiclesFilter.Transmission).length) {
            return true;
          }
          return e;
        })
        .sort((a, b) => {
          const priceA = a.TotalCharge?.RateTotalAmount || 0;
          const priceB = b.TotalCharge?.RateTotalAmount || 0;

          if (priceA === 0) return 1;
          if (priceB === 0) return -1;

          if (
            a.Status.toLowerCase() === "onrequest" &&
            b.Status.toLowerCase() !== "onrequest"
          ) {
            return 1;
          } else if (
            a.Status.toLowerCase() !== "onrequest" &&
            b.Status.toLowerCase() === "onrequest"
          ) {
            return -1;
          }
          return priceA - priceB;
        })
    );
  }
  useEffect(() => {
    segregateData();
    RefreshData();
  }, [allVehicles, vehiclesFilter]);

  useEffect(() => {
    RefreshData();
  }, [availableVehicles]);

  function requestParentResize() {
    const iframeHeight = document.body.scrollHeight + 100;
    window.parent.postMessage(
      {
        event_id: "iframeHeight",
        data: {
          iframeHeight: iframeHeight,
        },
      },
      "*"
    );
  }
  const infiteScrollContainer = useRef(null);
  const AppSettings = BookingGetters.getAppSettings();
  useEffect(() => {
    if (AppSettings?.showInIframe) {
      requestParentResize();
    }
  }, [showingVehicles, availableVehicles]);

  function RefreshData() {
    setShowingVehicles([]);
    loadMoreVehicles();
  }

  return (
    <>
      {!isLoadingMoreVehicles && (
        <div
          className="font-bold text-center text-black h-full flex flex-col gap-4 w-full h-[100vh]"
          ref={infiteScrollContainer}
        >
          {showingVehicles.length
            ? showingVehicles.map((el: Vehicle, index) => {
                return (
                  <div
                    key={index}
                    className="border-solid border-2 border-gray-300 rounded cursor-pointer hover:border-blue-500 hover:border-3"
                  >
                    <BookingVehicleInfoCard
                      extras={
                        el.optionals
                          ? el.optionals?.map((e) => {
                              return {
                                ...e,
                                iconUrl: e.Equipment.optionalImage || "#",
                                maxAllowed: e.Equipment.isMultipliable ? 20 : 1,
                                optionName: e.Equipment?.EquipType || "",
                                pricePerDayInUSD: (
                                  e.Charge.Amount || 0
                                ).toString(),
                                details: e.Equipment?.Description || "",
                              };
                            })
                          : []
                      }
                      discount={el.Vehicle?.payNowDis || 0}
                      status={el.Status}
                      modelYear=""
                      vehicleGroup={el.Vehicle?.groupPic?.nationalCode || ""}
                      priceDetails={{
                        payNow: (
                          el.TotalCharge?.RateTotalAmount || 0
                        ).toString(),
                        payLater: (
                          el.TotalCharge?.RateTotalAmount || 0
                        ).toString(),
                        feeAmout: (el?.payLaterFee || 0).toString(),
                        payLaterFee: (
                          el?.payLaterFee + el?.TotalCharge?.RateTotalAmount
                        ).toString(),
                      }}
                      specifications={
                        //merge sorting done
                        el.Vehicle?.vehicleParameter
                          .sort((e1, e2) => {
                            return e1["position :"] - e2["position :"];
                          })
                          .map((e) => {
                            return {
                              iconUrl: e["fileUrl :"]
                                ? e?.["fileUrl :"]
                                : e?.fileUrl,
                              name: e["name :"] ? e?.["name :"] : e?.name,
                              value: e["position :"],
                            };
                          }) || []
                      }
                      topFeature={""}
                      vehicleImages={[
                        el.Vehicle?.vehicleGroupPic || "",
                        ...(el.Vehicle?.vehicelExtraImage || []).filter((e) => {
                          return !!e;
                        }),
                      ]}
                      macroClass={el.Vehicle.macroClass}
                      vehicleName={el.Vehicle?.VehMakeModel?.Name || ""}
                      payLater={"Pay Later"}
                      disclaimer={
                        "Le opzioni di questo veicolo da consegnare possono variare"
                      }
                      payNow={"Pay Now"}
                      selectExtra={"Choose Insurance"}
                      closeExtra={"Chiudi extra"}
                      viewAllSpecifications={"Visualizza tutte le specifiche"}
                      moveToPayment={(e) => {
                        onVehicleSelect({
                          vehicleDetails: el,
                          paymentDetails: e,
                        });
                      }}
                    />
                  </div>
                );
              })
            : "No Vehicles Available"}
        </div>
      )}
      {isLoadingMoreVehicles && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </Box>
      )}
    </>
  );
}
